import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { TravelerDetailsModel } from '../models/traveler-details.model';
import { TravelerDetails } from '../actions';
import { Customer, GetPerksResponse, Perk } from '../../interfaces';
import { TravelInsurancePlanPerkService } from '../../services';
import { tap } from 'rxjs';
import { Checkout } from '../checkout.action';

export const TRAVEL_DETAILS_STATE_TOKEN = new StateToken<
  TravelerDetailsModel[]
>('travelerDetails');

@State<TravelerDetailsModel>({
  name: TRAVEL_DETAILS_STATE_TOKEN,
  defaults: {
    perks: [],
    customers: [],
    form: {
      model: [],
      perks: [],
    },
  },
})
@Injectable()
export class TravelerDetailsState {
  public _perks = inject(TravelInsurancePlanPerkService);

  @Selector()
  static perks({ perks }: TravelerDetailsModel): GetPerksResponse[] {
    return perks;
  }

  @Selector()
  static customers({ customers }: TravelerDetailsModel): Customer[] {
    return customers;
  }

  @Selector()
  static customersModel({ form: { model } }: TravelerDetailsModel) {
    return model;
  }

  @Selector()
  static customersPerks({ form: { perks } }: TravelerDetailsModel): Perk[][] {
    return perks;
  }

  @Action(TravelerDetails.SetCustomers)
  setCustomers(
    ctx: StateContext<TravelerDetailsModel>,
    { customers }: TravelerDetails.SetCustomers
  ) {
    ctx.patchState({
      customers,
      form: {
        perks: customers.map((c) => c.perks),
        model: customers.map(({ name, lastName }) => ({ name, lastName })),
      },
    });
  }

  @Action(TravelerDetails.UpdateFormValue)
  updateFormValue(
    ctx: StateContext<TravelerDetailsModel>,
    { index, model: newModel }: TravelerDetails.UpdateFormValue
  ) {
    const { form } = ctx.getState();

    const updatedModel = form.model.map((model, i) =>
      i === index ? newModel : model
    );

    ctx.patchState({ form: { ...form, model: updatedModel } });
  }

  @Action(TravelerDetails.UpdatePerksFormValues)
  updatePerksFormValues(
    ctx: StateContext<TravelerDetailsModel>,
    { perks }: TravelerDetails.UpdatePerksFormValues
  ) {
    const { form } = ctx.getState();
    ctx.patchState({ form: { ...form, perks } });
  }

  @Action(TravelerDetails.LoadPerks)
  loadPerks(
    ctx: StateContext<TravelerDetailsModel>,
    { planId, domainCode }: TravelerDetails.LoadPerks
  ) {
    if (planId === 0) return ctx.patchState({ perks: [] });

    return this._perks
      .fetchByPlanAndDomain({ planId, domainCode })
      .pipe(tap((perks) => ctx.patchState({ perks })));
  }

  @Action(TravelerDetails.AddPerk)
  addPerk(
    ctx: StateContext<TravelerDetailsModel>,
    { index, perk: newPerk }: TravelerDetails.AddPerk
  ) {
    const { form } = ctx.getState();

    // Update the client's perks at the specified position
    const updatedPerks = form.perks.map((perks, i) =>
      i === index ? [...perks, newPerk] : perks
    );

    ctx.patchState({ form: { ...form, perks: updatedPerks } });
    ctx.dispatch(
      new Checkout.UpdatePerks(updatedPerks.map((c) => c.map((p) => p.id)))
    );
  }

  @Action(TravelerDetails.RemovePerk)
  removePerk(
    ctx: StateContext<TravelerDetailsModel>,
    { index, perkId }: TravelerDetails.RemovePerk
  ) {
    const { form } = ctx.getState();

    // Filter perks to remove the perk with the specified id
    const updatedPerks = form.perks.map((perks, i) =>
      i === index ? perks.filter((perk) => perk.id !== perkId) : perks
    );

    ctx.patchState({ form: { ...form, perks: updatedPerks } });
    ctx.dispatch(
      new Checkout.UpdatePerks(updatedPerks.map((c) => c.map((p) => p.id)))
    );
  }
}
