import { inject } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { OrderPaymentStateEnum } from '@app/features/checkout/interfaces';
import { PaymentMethodService } from '@app/features/checkout/services';
import { CheckoutState } from '@app/features/checkout/store';
import { Payment } from '@app/features/checkout/store/actions';
import { PaymentState } from '@app/features/checkout/store/states';
import { toggleMainLoader } from '@app/helpers';
import { PaymentEngineType } from '@app/interfaces';
import { Store } from '@ngxs/store';
import { NgxUiLoaderService } from 'ngx-ui-loader';

export abstract class PaymentComponent {
  private router = inject(Router);

  protected store = inject(Store);
  protected _service = inject(PaymentMethodService);
  protected _ngxLoader = inject(NgxUiLoaderService);
  protected orderId = this.store.selectSignal(PaymentState.orderId);
  protected isPaying = this.store.selectSignal(PaymentState.paying);
  protected entityId = this.store.selectSignal(CheckoutState.entityId);
  protected abstract readonly paymentEngineType: PaymentEngineType;

  public accordion!: MatAccordion;

  protected insertPaymentState(
    orderPaymentState: OrderPaymentStateEnum,
    paymentResponse = ''
  ): void {
    this._service
      .insertCustomState({
        orderId: this.orderId(),
        paymentId: '',
        paymentResponse,
        orderPaymentState,
        paymentEngineType: this.paymentEngineType,
      })
      .subscribe();
  }

  protected setPayingStatus(paying: boolean): void {
    this.store.dispatch(new Payment.SetPayingStatus(paying));
  }

  protected goToPaymentConfirmation(): void {
    const hashcode = this.store.selectSnapshot(CheckoutState.hashcode);
    this.router.navigate(['/cotizar/confirmar-pago', hashcode]);
    this.setPayingStatus(false);
    toggleMainLoader(false);
  }

  protected disableAccordionPanels() {
    if (this.accordion) {
      this.accordion._headers.forEach(({ panel }) => {
        panel.disabled = true;
      });
    }
  }

  protected enableAccordionPanels() {
    if (this.accordion) {
      this.accordion._headers.forEach(({ panel }) => {
        panel.disabled = false;
      });
    }
  }
}
