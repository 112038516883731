import { Injectable } from '@angular/core';
import {
  NgxsOnInit,
  Selector,
  State,
  StateContext,
  StateToken,
} from '@ngxs/store';
import { PaymentMethod } from '../../interfaces';
import { ParametersModel } from '../models/parameters.model';

import paymentsMethods from '../../../../../assets/data/payment-methods.json';

export const PARAMETERS_SELECTION_STATE_TOKEN = new StateToken<ParametersModel>(
  'parameters'
);

@State<ParametersModel>({
  name: PARAMETERS_SELECTION_STATE_TOKEN,
  defaults: {
    paymentMethods: [],
  },
})
@Injectable()
export class ParametersState implements NgxsOnInit {
  ngxsOnInit(ctx: StateContext<ParametersModel>) {
    window.env.parameters.then((parameter) =>
      ctx.setState({ paymentMethods: parameter.paymentMethods })
    );
  }

  @Selector()
  static paymentMethods({ paymentMethods }: ParametersModel): PaymentMethod[] {
    return paymentMethods.flatMap((parameterPaymentMethod) => {
      let resultPayments: PaymentMethod[] = [];

      for (const paymentMethod of paymentsMethods as PaymentMethod[]) {
        if (paymentMethod.value == parameterPaymentMethod.name) {
          resultPayments.push(paymentMethod);
        }
      }

      return resultPayments;
    });
  }
}
