<div
  [ngClass]="{ 'ps-0': !showCheckoutButton }"
  class="form-check mt-3 pe-4 d-flex align-items-center"
>
  <input
    type="checkbox"
    id="policy-check"
    [checked]="policyCheck()"
    (change)="onPolicyCheckboxChange()"
    *ngIf="showCheckoutButton"
    class="form-check-input me-3 my-auto"
    [ngClass]="{ invalid: showInvalidPolicyCheck() }"
  />
  <label class="form-check-label f-14 fw-400" for="policy-check">
    <span *ngIf="!showCheckoutButton">{{
      "Al continuar acepto las condiciones de compra " | translate
    }}</span>
    <span *ngIf="showCheckoutButton">{{
      "Leí y acepto las condiciones de compra " | translate
    }}</span>

    <span class="fw-700 Gotham text-blue">{{
      "políticas de privacidad y términos y condiciones del servicio."
        | translate
    }}</span>
  </label>
</div>

<div class="form-check mt-3 pe-4 d-flex align-items-center">
  <input
    class="form-check-input me-3 my-auto"
    type="checkbox"
    id="newsletter-check"
  />
  <label class="form-check-label f-14 fw-400" for="newsletter-check">
    {{ "Quiero recibir promociones y ofertas de" | translate }}
    <span class="fw-700 Gotham">Seguroparaviaje.com,</span>
    {{ "consulta más información en política de privacidad." | translate }}
  </label>
</div>

<div *ngIf="showCheckoutButton">
  <button
    type="button"
    (click)="checkout()"
    class="mt-4 border-btn-selected text-blue fw-700 f-10 f-lg-16 f-xl-16 py-2 w-btn-pay Gotham w-100"
  >
    {{ submitLabel | translate }}
  </button>
</div>
