import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { CheckoutModel, PlanSelectionModel } from '../models';
import { Payment, PlanSelection } from '../actions';
import { Plan, PlanBenefit } from '../../interfaces';
import { PlanSelectionService } from '../../services';
import { Observable, of, tap } from 'rxjs';
import { CheckoutState } from '../checkout.state';

export const PLAN_SELECTION_STATE_TOKEN = new StateToken<PlanSelectionModel>(
  'planSelection'
);

@State<PlanSelectionModel>({
  name: PLAN_SELECTION_STATE_TOKEN,
  defaults: {
    plans: [],
    current: null,
    planDetail: null,
    planBenefits: [],
  },
})
@Injectable()
export class PlanSelectionState {
  private _service = inject(PlanSelectionService);

  @Selector()
  static currentPlan({ current }: PlanSelectionModel): Plan | null {
    return current;
  }

  @Selector()
  static plans({ plans }: PlanSelectionModel) {
    return plans;
  }

  @Selector()
  static topPlans({ plans }: PlanSelectionModel) {
    return plans.slice(0, 3);
  }

  @Selector()
  static othersPlans({ plans }: PlanSelectionModel) {
    return plans.slice(3);
  }

  @Selector()
  static planBenefits({ planBenefits }: PlanSelectionModel) {
    return planBenefits;
  }

  @Selector([PlanSelectionState, CheckoutState])
  static currencies(_: PlanSelectionModel, { currencies }: CheckoutModel) {
    return currencies;
  }

  @Selector()
  static planDetail({ planDetail }: PlanSelectionModel) {
    return planDetail;
  }

  @Action(PlanSelection.SetPlans)
  setPlans(
    ctx: StateContext<PlanSelectionModel>,
    payload: PlanSelection.SetPlans
  ) {
    ctx.patchState({
      plans: payload.plans,
      planBenefits: payload.planBenefits,
    });
  }

  @Action(PlanSelection.SelectPlan)
  selectPlan(
    ctx: StateContext<PlanSelectionModel>,
    { plan: current }: PlanSelection.SelectPlan
  ) {
    ctx.patchState({ current });
    return ctx.dispatch(new Payment.SetStripePayment(0, ''));
  }

  @Action(PlanSelection.ShowDetails)
  showDetails(
    ctx: StateContext<PlanSelectionModel>,
    { id }: PlanSelection.ShowDetails
  ): Observable<PlanBenefit[]> {
    if (ctx.getState().planDetail?.plan.id == id) {
      return of(ctx.getState().planDetail?.benefits ?? []);
    }

    return this._service.fetchPlanBenefits(id).pipe(
      tap((benefits) => {
        const plans = ctx.getState().plans;

        ctx.patchState({
          planDetail: {
            plan: plans.find((p) => p.id === id) ?? plans[0],
            benefits,
          },
        });
      })
    );
  }
}
