import { Component, OnInit, ViewChild } from '@angular/core';
import { OrderPaymentStateEnum } from '@app/features/checkout/interfaces';
import { TranslateModule } from '@ngx-translate/core';
import { PurchaseActionComponent } from '../../purchase-action/purchase-action.component';
import { NgIf } from '@angular/common';
import { environment } from '@env/environment';
import { PaymentState } from '@app/features/checkout/store/states';
import { PaymentComponent } from '../base-payment.component';
import {
  injectStripe,
  StripeElementsDirective,
  StripePaymentElementComponent,
} from 'ngx-stripe';
import { StripeElementsOptions } from '@stripe/stripe-js';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { PaymentEngineType } from '@app/interfaces';
import { ErrorAlert } from '@app/helpers';

@Component({
  selector: 'app-stripe-payment',
  templateUrl: './stripe-payment.component.html',
  styleUrls: ['./stripe-payment.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    PurchaseActionComponent,
    ReactiveFormsModule,
    NgIf,
    StripeElementsDirective,
    StripePaymentElementComponent,
  ],
})
export class StripePaymentComponent extends PaymentComponent implements OnInit {
  @ViewChild(StripePaymentElementComponent)
  paymentElement!: StripePaymentElementComponent;

  protected override paymentEngineType = PaymentEngineType.Stripe;

  public readonly stripe = injectStripe(environment.stripeApiKey);

  public payerName = new FormControl('', Validators.required);
  public elementsOptions: StripeElementsOptions = {
    locale: 'es',
    appearance: {
      theme: 'stripe',
      variables: {
        fontSizeSm: '16px',
        fontFamily: 'Gotham',
        colorPrimary: '#2855A0',
      },
      rules: {
        '.Label': {
          // fontSize: '16px',
          fontWeight: '700',
          // fontFamily: 'Gotham',
          lineHeight: '19.2px',
        },
        '.Input': {
          color: '#000000',
          height: '48px',
          border: '2px solid #E0E0E0',
          fontSize: '16px',
          boxShadow: '0px 2px 4px 0px #00000012',
          fontWeight: '400',
          lineHeight: '19.2px',
        },
        '.Input:focus': {
          outline: '0px',
          boxShadow: '0px 0px 4px #80b9ee',
          borderColor: 'hsla(218, 60%, 39%, 50%)',
        },
      },
    },
  };

  ngOnInit(): void {
    this._ngxLoader.startLoader('payment-loader');

    const clientSecret = this.store.selectSnapshot(
      PaymentState.stripeClientSecret
    );

    if (!!clientSecret) {
      this.initConfig(clientSecret);
    } else {
      this.disableAccordionPanels();
      this._service
        .stripeCreate({
          orderId: this.orderId(),
          requestId: this.orderId() == 0 ? this.entityId() : 0,
        })
        .subscribe({
          next: ({ clientSecret }) => this.initConfig(clientSecret),
          error: ({ error }) => {
            this.enableAccordionPanels();
            this._ngxLoader.stopLoader('payment-loader');
            ErrorAlert.fire({ text: error.error.message }).finally(() =>
              this.accordion.closeAll()
            );
          },
        });
    }
  }

  initConfig(clientSecret: string) {
    this.elementsOptions.clientSecret = clientSecret;
    this.enableAccordionPanels();
    this._ngxLoader.stopLoader('payment-loader');
  }

  collectPayment() {
    if (this.isPaying() || this.payerName.invalid) return;

    this.setPayingStatus(true);
    this.stripe
      .confirmPayment({
        elements: this.paymentElement.elements,
        confirmParams: {
          payment_method_data: {
            billing_details: {
              name: this.payerName.value,
            },
          },
        },
        redirect: 'if_required',
      })
      .subscribe({
        next: (result) => {
          if (result.error) {
            this.setPayingStatus(false);

            ErrorAlert.fire({ text: result.error.message });
          } else if (result.paymentIntent.status === 'succeeded') {
            // Payment processed successfully
            setTimeout(() => this.goToPaymentConfirmation(), 5000);
          }
        },
        error: (err) => {
          this.insertPaymentState(
            OrderPaymentStateEnum.Refused,
            JSON.stringify(err)
          );

          ErrorAlert.fire();
        },
      });
  }
}
